// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/footer.tsx");
  import.meta.hot.lastModified = "1727600610614.0564";
}
// REMIX HMR END

import { Link, useFetcher } from "@remix-run/react";
import React, { useEffect, useState } from "react";
import socialMedia from "~/data/socialMedia";
import apps from "~/data/apps";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { BsFillPhoneFill } from "react-icons/bs";
const Footer = ({
  data
}) => {
  _s();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  const [socials, setSocials] = useState(socialMedia);
  const [appLinks, setAppLinks] = useState(apps);
  const fetcherAPI = useFetcher();
  useEffect(() => {
    fetcherAPI.load("/api/getSettingsData");
  }, []);
  useEffect(() => {
    if (!fetcherAPI || !fetcherAPI.data || fetcherAPI.state === "loading") {
      return;
    }
    if (fetcherAPI.data) {
      const socialLinks = fetcherAPI.data.socialLinks;
      const appStoreLinks = fetcherAPI.data.appLinks;
      setSocials(socialLinks);
      setAppLinks(appStoreLinks);
    }
  }, [fetcherAPI.data]);
  return <footer className="mainfooter" role="contentinfo">
      <div className="footer-middle">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4">
            {data.map((column, index) => <div key={index} className="w-full md:w-1/4 px-4">
                <div className="footer-pad">
                  <h4 className="mb-4 mt-4 font-semibold">{column.heading}</h4>
                  <ul className="list-unstyled">
                    {column.links.map((link, linkIndex) => <li key={linkIndex}>
                        <Link to={link.to}>{link.linkText}</Link>
                      </li>)}
                  </ul>
                </div>
              </div>)}
            <div className="w-full md:w-1/4 px-4">
              <div className="footer-pad">
                <h4 className="mb-4 mt-4 font-semibold">Follow Us</h4>
                <ul className="social-network social-circle flex">
                  {socials.map((social, index) => <li>
                      <Link to={social.link} className="footer-social-icon" title={social.name} key={index}>
                        <img src={social.icon} alt={social.link} loading="lazy" />
                      </Link>
                    </li>)}
                </ul>
                <h4 className="mb-4 mt-4 font-semibold">Get Our Apps</h4>
                <ul className="app-network flex">
                  {appLinks.map((app, index) => <li>
                      <Link to={app.link} className="footer-app-icon" title={app.name} key={index}>
                        <img src={app.icon} alt={app.link} loading="lazy" />
                      </Link>
                    </li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-6 address">
            <p className="icon-container">
              <FaLocationDot />
            </p>
            <p>
              NIC Faisalabad, First Floor, Expo Center, University of
              Agriculture, Jail Road, Faisalabad, Punjab
            </p>
          </div>
          <div className="flex justify-center mt-6 contacts-container">
            <div className="contact-num flex">
              <FaPhoneVolume /> 0412625506
            </div>
            <div className="contact-num flex">
              <BsFillPhoneFill /> +923241806008
            </div>
          </div>
          <div className="flex justify-center mt-6 copy">
            <p>
              &copy; Copyright {currentYear} - Salam Entertainment Pvt. Ltd. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>;
};
_s(Footer, "4db/t+IHxV0m/JTGeXTBlszDLcw=", false, function () {
  return [useFetcher];
});
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;